<template>
  <div class="container">
    <SkeletonAnimate
      :visible="preloader"
    />

    <div class="container__control">
      <ClosePopUpButton
        class="black"
      />
    </div>

    <div class="container__content">
      <h3>
        {{ $t('components.ui.pages.pop-up.withdrawal-method.withdrawal-method') }}
      </h3>

      <SelectInput
        style="margin: 20px 0 15px;"
        :placeholder="`${$t('components.ui.pages.pop-up.withdrawal-method.cryptocurrency')}*`"
        :list="currencies"
        label="name"
        v-model="form.cryptocurrency"
        :errors="form.errors.cryptocurrency"
      />

      <TextInput
        :placeholder="`${$t('components.ui.pages.pop-up.withdrawal-method.address')}*`"
        v-model="form.wallet"
        :reg-exp="/[^A-z0-9]/iu"
        :replace-symbol="'_'"
        :errors="form.errors.wallet"
      />

      <div class="container__content__info">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8" cy="8" r="8" fill="#DA2828"/>
          <path d="M7.40638 9.33731L7.31496 4H8.70924L8.62924 9.33731H7.40638ZM7.25781 10.3881H8.74353V12H7.25781V10.3881Z" fill="white"/>
        </svg>

        {{ $t('components.ui.pages.pop-up.withdrawal-method.info') }}
      </div>

      <BlueButton
        style="margin-top: 20px;"
        :text="$t('components.ui.pages.pop-up.withdrawal-method.add-wallet')"
        :loading="loading"
        :disabled="loading"
        @click="submit()"
      />
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import SkeletonAnimate from '@/ui/preloader/SkeletonAnimatePreloader.vue'
import ClosePopUpButton from '@/ui/buttons/ClosePopUpButton.vue'
import SelectInput from '@/ui/inputs/Select.vue'
import TextInput from '@/ui/inputs/Text.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    SkeletonAnimate,
    ClosePopUpButton,
    SelectInput,
    TextInput,
    BlueButton
  },
  data () {
    return {
      v: useVuelidate(),
      preloader: true,
      loading: false,
      currencies: [],
      form: {
        cryptocurrency: null,
        wallet: '',

        errors: {
          cryptocurrency: [],
          wallet: []
        }
      }
    }
  },
  validations () {
    return {
      form: {
        cryptocurrency: { required },
        wallet: { required }
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const currencies = await this.$axios.get(
          '/private/currencies?type=withdraw')
        if (currencies.status === 200) {
          this.currencies = currencies.data.data
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
      }
    },
    async submit () {
      this.loading = true
      this.v.form.$touch()
      this._setErrors()

      if (this._countError() === 0) {
        try {
          const formData = new FormData()
          formData.append('currency_id', this.form.cryptocurrency.id)
          formData.append('address', this.form.wallet.trim())

          const currencies = await this.$axios.post(
            '/private/wallets', formData)
          if (currencies.status === 200) {
            const data = currencies.data.data
            this.$pop.up({
              name: 'addWalletConfirm',
              wallet: {
                id: data.id,
                address: data.address,
                currency: {
                  name: this.form.cryptocurrency.name
                }
              }
            })
          }
        } catch (err) {
          if (err.data?.code === 'wallet-used' || err.data?.code === 'wallet-exist') {
            this.$pop.up({
              name: 'successfully',
              type: 'error',
              title: `${this.$t('components.ui.pages.pop-up.withdrawal-method.error-wallet-used-title')} ${this.hiddenWallet(this.form.wallet.trim())}`,
              text: this.$t('components.ui.pages.pop-up.withdrawal-method.error-wallet-used-text'),
              button: {
                tag: 'button',
                text: this.$t('components.ui.pages.pop-up.withdrawal-method.error-wallet-used-button'),
                to: 'withdrawalMethods'
              }
            })
          }
        } finally {
          this.loading = false
        }
      }

      this.loading = false
    },
    _setErrors () {
      const errorsForm = this.v.form
      this.form.errors = {
        cryptocurrency: [],
        wallet: []
      }

      // Cryptocurrency
      if (errorsForm.cryptocurrency.required.$invalid) {
        this.form.errors.cryptocurrency.push('errors.required')
      }

      // Address
      if (errorsForm.wallet.required.$invalid) {
        this.form.errors.wallet.push('errors.required')
      }
      if (this.form.cryptocurrency?.coin && this.form.wallet) {
        var WAValidator = require('multicoin-address-validator')
        var valid = WAValidator.validate(this.form.wallet, this.form.cryptocurrency.coin.toLowerCase())

        if (!valid) {
          this.form.errors.wallet.push('errors.crypto-address')
        }
      }
    },
    _countError () {
      const errors = this.form.errors
      var count = 0

      count += errors.cryptocurrency.length +
        errors.wallet.length

      return count
    },
    hiddenWallet (wallet) {
      const start = wallet.slice(0, 4)
      const end = wallet.slice(wallet.length - 4, wallet.length)

      return `${start}*****${end}`
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  max-width: 430px;
  padding: 10px;
  background: white;
  border-radius: 15px;

  .container__control {
    display: flex;
    justify-content: flex-end;
  }

  .container__content {
    padding: 0 10px 20px 10px;

    h3 {
      line-height: 100%;
      color: rgb(var(--color-dark));
      font-family: SemiBold;
    }

    .container__content__info {
      margin-top: 10px;
      padding: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
      background: rgba(218, 40, 40, .1);
      border-radius: 5px;
      font-size: 12px;
      line-height: 100%;
      color: #DA2828;
      font-family: Medium;
    }
  }
}
</style>
